@import "../../styles/styles";

.manage_user {
  &__tableHeadRow {
    width: auto;
    height: 17px;
    text-align: left;
    font-family: var(--fontFamily);
    font-size: 14px;
    font-weight: 500;
    font-style: var(--normalFontStyle);
    font-stretch: var(--normalFontStretch);
    line-height: var(--normalLineHeight);
    letter-spacing: var(--normalLetterSpacing);
    color: var(--darkBlueColor);
    margin-left: 31px;
  }

  &__tableContainer {
    margin-top: 20px;
    min-width: 910px;
    width: 100%;
  }

  &__dateContainer {
    display: flex;
    align-items: center;
  }

  &__categoryContainer {
    display: flex;
    align-items: center;
  }

  &__warningIconContainer {
    padding-top: 5px;
    padding-right: 15px;
  }

  &__warningCategoryIconContainer {
    padding-top: 7px;
    padding-left: 10px;
  }

  &__actionsTableHeadRow {
    margin-left: 0px;
    width: auto;
    height: 17px;
    text-align: left;
    font-family: var(--fontFamily);
    font-size: 14px;
    font-weight: 500;
    font-style: var(--normalFontStyle);
    font-stretch: var(--normalFontStretch);
    line-height: var(--normalLineHeight);
    letter-spacing: var(--normalLetterSpacing);
    color: var(--darkBlueColor);
  }

  &__tableRow {
    width: auto;
    height: 27px;
    text-align: left;
    color: var(--lightBlackColor);
  }

  &__darkTableRow {
    background-color: var(--lightGrayColor);
    border-color: transparent;
  }

  &__userId {
    color: var(--darkSkyBlue);
    font-weight: 500;
  }

  &__title {
    font-size: 26px;
    color: var(--darkSkyBlue);
    font-weight: 500;
  }

  &__sortIcon {
    height: 10px;
    color: var(--darkGrayColor);
  }

  &__sortIconActive {
    height: 20px;
    color: var(--darkSkyBlue);
  }

  &__columnTitle {
    white-space: nowrap;
  }

  &__statusLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //height: -webkit-fill-available;
    color: var(--whiteColor) !important;
    text-align: -webkit-center;
    //margin and padding needed to correctly fill the NativeSelect component with grey color when disabled
    //change it if you change the padding-left of __darkBlueOption, __darkRedOption, __darkSkyBlueOption or __lightBlueOption
    margin-left: -25px;
    padding-left: 25px;

    &:not([multiple]) option {
      color: var(--whiteColor);
      background-color: var(--darkBlackColor);
    }
  }

  &__darkBlueOption {
    min-width: 150px;
    width: 80px;
    border-radius: 3px;
    background-color: var(--darkBlueColor);
    padding-left: 25px;

    &:focus {
      color: var(--whiteColor);
      background-color: var(--darkBlueColor);
    }
  }

  &__darkRedOption {
    min-width: 150px;
    width: 80px;
    border-radius: 3px;
    background-color: var(--darkRedColor);
    padding-left: 25px;

    &:focus {
      color: var(--whiteColor);
      background-color: var(--darkRedColor);
    }
  }

  &__darkSkyBlueOption {
    min-width: 150px;
    width: 80px;
    border-radius: 3px;
    background-color: var(--darkSkyBlue);
    padding-left: 25px;

    &:focus {
      color: var(--whiteColor);
      background-color: var(--darkSkyBlue);
    }
  }

  &__lightBlueOption {
    min-width: 150px;
    width: 80px;
    border-radius: 3px;
    background-color: var(--lightBlueColor);
    padding-left: 25px;

    &:focus {
      color: var(--whiteColor);
      background-color: var(--lightBlueColor);
    }
  }

  &__inputDisabled {
    background-color: var(--grayColor);

    & svg {
      display: none;
    }
  }

  &__topContainer {
    margin-left: 20px;
  }

  &__loadingSpinner {
    margin-top: 100px;
    text-align: center;
    overflow: hidden;
  }

  &__datePickerInputFieldDisabled {
    &:before {
      border-bottom-style: none !important;
    }

    &:focus {
      border-bottom-style: none !important;
    }
  }

  &__datePickerInputField {
    width: 180px;
    height: 44px;
    border-radius: 2px;
    text-decoration: none;
    border-bottom: none !important;
    border-bottom-style: none !important;
    border-bottom-color: #394e74;
    padding-left: 0 !important;
    justify-content: center;
    &:before {
      border-bottom: none;
    }

    &:focus {
      border-bottom: none;
    }
  }

  &__datePickerInputFieldLabel {
    height: 19px;
    font-family: var(--fontFamily);
    font-size: 16px !important;
    font-weight: 500 !important;
    font-style: var(--normalFontStyle);
    font-stretch: var(--normalFontStretch);
    line-height: var(--normalLineHeight);
    letter-spacing: var(--normalLetterSpacing);
    color: var(--lightBlackColor);
    margin-top: -7px !important;
    border-bottom: none !important;
  }

  &__actionIcon {
    width: 24px;
    height: 24px;
    color: var(--lightBlueColor);
    border: 1px solid var(--lightBlueColor);
  }

  &__errorMessage {
    @include titre;
    margin: 25px 0;
  }

  &__toolBarPaginationElement {
    position: absolute;
    right: 0px;
    padding-right: 0px !important;
  }

  &__manageUsersLoader {
    position: relative;
    left: 40%;
    top: 100px;
  }

  &__pagination {
    text-align: right;
  }

  &__paginationFooter {
    float: right;
    padding: 10px;
  }

  &__tooltipPlacementBottom {
    margin: 0px 0;
  }

  &__warningColor {
    color: #ffc107;
    margin-top:4px;
  }

  &__errorColor {
    color: #dc3545;
    margin-top:4px;
  }
}

.more_time {
  &__action_icon {
    width: 24px;
    height: 24px;
    color: var(--lightBlueColor);
  }
}